import React from 'react';

const Card = ({header,body,footer}) => {
    return <div className="row card">
        <div className="card-header">
            <div className="card-title">{header || ' '}</div>
        </div>
        <div className="card-body">
            <p className="card-text">
                {body || ' '}
            </p>
        </div>
        <div className="card-footer text-right"><small>{footer || ' '}</small></div>
    </div>
}
export default Card;