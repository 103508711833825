import React from 'react';

import randomCard from './Randomizer'
import Card from '../Card'

const factCard = () => {
    const {title, fact, footer} = randomCard();
    return <Card
        header={ title || 'Losowy koci fakt:' }
        footer={footer}
        body={fact}
        />
}

export default factCard;