import React, { Component, Fragment } from "react";
// import { Provider } from "react-redux";
// import { PersistGate } from "redux-persist/integration/react";
import { toast } from "react-toastify";
import Card from './component/Card'
import Article from './component/Articles/Articles'
import FactCard from './component/Facts/'

toast.configure({ position: toast.POSITION.BOTTOM_RIGHT });

class App extends Component {

  render() {

    return (
      <Fragment>
        <div className="row"><div className="col-sm"><br/><br/></div></div>
        <div className="row"><div className="col-1"><br/></div>
          <div className="col-10">
              <FactCard />
          </div>
        </div>
        <div className="row">&nbsp;</div>
        <div className="row">
          <div className="col-1"><br/></div>
          <div className="col-10">
            <Card
              header="MAINE COON"
              body={Article()}
              footer="autor: Eliza, lat 8."
            />
            <div className="row">&nbsp;</div>
            <Card
              body="Tu powstaje sklep dla miłośników kociego designu. Nasze koty szukają dla Ciebie najlepszych kąsków."
              footer="zespół Kocham Koty"
            />
          </div>
          <div className="col-1"></div>
        </div>
      </Fragment>
    );
  }
}

export default App;