import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from './serviceWorker';

import "./assets/style.scss"
import "react-toastify/dist/ReactToastify.css";
import "react-datepicker/dist/react-datepicker.min.css";
import "react-datepicker/dist/react-datepicker.min";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/css/bootstrap.min.css.map";
//bootstrap dependencies:
import "jquery/dist/jquery.slim.min"
import "jquery/dist/jquery.slim.min.map"
import "popper.js/dist/popper.min"
import "popper.js/dist/popper.min.js.map"
import "bootstrap/dist/js/bootstrap.bundle.min";
import "bootstrap/dist/js/bootstrap.bundle.min.js.map";
//import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";


ReactDOM.render(<App />, document.getElementById('container'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
