import React,  { Fragment } from 'react';

const Article = () => {
      return <Fragment>
    Ta stara rasa kotów o grubej sierści chroniącej przed zimnym klimatem powstała w USA.<br/>
    Ich umarszczenie może być różnej barwy z różnymi zaznaczeniami, z których bardzo częste są odmiany pręgowane. Maine coony są kotami oddanymi właścicielom, ale lubiącymi też przebywać na dworzu. Potrzebują dużo przestrzeni do polowań i zabawy. Słyną z tego że do spania znajdują różne nieoczekiwane miejsca, lubią sypiać także na zewnątrz.<br/>
    <br/><br/>
    Ciekawostka:<br/>
    Koty rasy Maine Coon mają bardzo puszyste ogony co najmniej tak długie jak ich ciało. Służą im do okrywania się podczas snu.<br/>
    <br/>
    Kolor oczu: niebieski, miedziany, złoty lub zielony.<br/>
    Umarszczenie: różne z różnymi znaczeniami.<br/>
    Charakterystyka: przyjazny i chętny do zabawy.<br/>
    Cechy charakterystyczne: uwielbia przebywać na dworze.<br/>
    Pochodzenie: USA.
    </Fragment>
    }

export default Article;